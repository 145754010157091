<script>
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
// import DisplayCmp from "./components/DisplayCmp";
export default {    
  props: { reloadOrders: {} , reloadOrdersDelete :{}},
  components: {  Dataset, DatasetItem, /* DatasetInfo,*/DatasetPager, /* DatasetSearch,*/ DatasetShow  },
  data() {
    return {
      List_Dotations: [],
      cols: [
         {
          name : "Référence ",
          field: "ref",
          sort : ''
        },
        {
          name : "Demandé par ",
          field: "requestedBy",
          sort : ''
        },
        {
          name : "Date de requête",
          field: "created_at",
          sort : ''
        },
        {
          name : "Status ",
          field: "statusPlain",
          sort : ''
        },
      ],
      excelheader:
         {
          "Référence "          : "ref",
          "Demandé par"         : "requestedBy",
          "Motif de la demande" : "reason",
          "Imputation"          : "analyticAccount",
          "Date d'arrivée"      : "date",
          "Date de sortie"      : "date_to",
          "Date de requête"     : "created_at",
          "Status"              : "status",
          "Supervisé par"       : "supervisor",
          "Approuvée par"       : "approvedBy",
          "Destination à "      : "lifebase",
        },
      selectedRows : [],
      selectedIndex: -1,
      active       : false,
      requestsFull : [],
      drafts       : [],
      pendings     : [],
      confirms     : [],
      valids       : [],
      rejects      : [],
      filterForm : {
        selectedBdv    : [],
        etat           : '',
        dateRange      : '',
        analyticAccount: ''
      },
      etat : [],
      
      customDateRangeShortcuts: [
        { key: "thisWeek", label  : "Cette semaine", value    : "isoWeek" },
        { key: "lastWeek", label  : "Semaine dernière", value : "-isoWeek" },
        { key: "last7Days", label : "7 derniers jours", value : 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label : "Ce mois", value          : "month" },
        { key: "lastMonth", label : "Dernier mois", value     : "-month" },
        { key: "thisYear", label  : "Cette année", value      : "year" },
        { key: "lastYear", label  : "L'année dernière", value : "-year" },
      ],
    };
  },
  computed: {
  },
  mounted() {
    this.refreshDotationsRequestsTable();
  },
  watch:{
    
    selectedRows(){
      if(this.selectedRows.length && undefined != this.selectedRows[0]){
        this.$emit('selectedOrders',this.selectedRows)
      }else{
        this.$emit('selectedOrders',[])
      }
    },
    reloadOrdersDelete:{
        handler(val){
          if(val){
            this.refreshDotationsRequestsTable();
            this.selectedRows = []; this.selectedIndex = -1;
          }
        }
    },
    reloadOrders:{
        handler(val){
          if(val){
            this.refreshDotationsRequestsTable();
          }
        }
      }
    
  },
  methods: {

    ListRequests(data){
      if(this.etat == data)
      this.resetFilter()
      else{
        this.etat            = data;
        this.filterForm.etat = data;
        if(data == 'draft') this.List_Dotations = this.drafts;
        else if(data == 'pending_confirmation') this.List_Dotations = this.pendings;
        else if(data == 'confirmed') this.List_Dotations = this.confirms;
        else if(data == 'approved') this.List_Dotations = this.valids;
        else if(data == 'rejected') this.List_Dotations = this.rejects;
      }
      if(!this.active) this.active = !this.active
    },
    getFilteredList(){
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/requests/dotation/filterdList",  {from: this.filterForm.dateRange.start,
          to: this.filterForm.dateRange.end, etat: this.filterForm.etat ,analyticAccount: this.filterForm.analyticAccount})
        .then((res) => {
          this.List_Dotations     = res.data.original.list;
          this.filterData();
          this.$emit('reloadOrdersDone',false);
          loader.hide();
        })
        .catch(() => {
          loader.hide();

          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    filterData(){
      this.drafts       = this.List_Dotations.filter(x => (x.statusPlain == 'draft'));
      this.pendings     = this.List_Dotations.filter(x => (x.statusPlain == 'pending_confirmation'));
      this.confirms     = this.List_Dotations.filter(x => (x.statusPlain == 'confirmed'));
      this.valids       = this.List_Dotations.filter(x => (x.statusPlain == 'approved'));
      this.rejects      = this.List_Dotations.filter(x => (x.statusPlain == 'rejected'));
    },
    resetFilter(){
      this.selectedCTT                = [];
      this.filterForm.selectedBdv     = [];
      this.filterForm.dateRange       = [];
      this.filterForm.etat            = "";
      this.List_Dotations             = this.requestsFull;
      this.filterForm.analyticAccount = "";
      this.etat                       = "";
      this.filterData();
    },
    /**
     * Search the table data with search input
     */
    refreshTable() {
      // console.log(this);
    },
    refreshDotationsRequestsTable() {
      let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
      })
      this.$http
        .post("/ops/requests/dotation/list")
        .then((res) => { 
          this.List_Dotations = res.data.original.list;
          this.requestsFull = this.List_Dotations;
          this.drafts       = this.List_Dotations.filter(x => (x.statusPlain == 'draft'));
          this.pendings     = this.List_Dotations.filter(x => (x.statusPlain == 'confirmed'));
          this.confirms     = this.List_Dotations.filter(x => (x.statusPlain == 'confirmed'));
          this.valids       = this.List_Dotations.filter(x => (x.statusPlain == 'approved'));
          this.rejects      = this.List_Dotations.filter(x => (x.statusPlain == 'rejected'));
          
          this.selectedRows = []; this.selectedIndex = -1;
          this.$emit('reloadOrdersDone',false);
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },
  },
};
</script>

<template>
  <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
         <div class="row">
          <div class="col-sm-12 col-md-4 ">
            <div class="btn-group col-12">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'draft'}"
                  @click="ListRequests('draft')"
                >
                  <i class="fas fa-file-signature"></i> <span class="badge bg-secondary text-white">{{ drafts.length }}</span> Brouillon
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'confirmed'}"
                  @click="ListRequests('confirmed')"
                >
                  <i class="fas fa-check text-info"></i> <span class="badge bg-info text-white">{{ confirms.length }}</span> Confirmé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'approved'}"
                  @click="ListRequests('approved')"
                >
                  <i class="fas fa-check-double text-success"></i>
                  <span class="badge bg-success text-white ml-1"> {{ valids.length }}</span> Validé
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  :class="{'active':etat == 'rejected'}"
                  @click="ListRequests('rejected')"
                >
                  <i class="fas fa-ban text-danger"></i>
                  <span class="badge bg-danger text-white ml-1"> {{ rejects.length }}</span> Rejeté
                </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
              <VueCtkDateTimePicker
                locale="fr"
                v-model="filterForm.dateRange"
                style="display:inline-block;margin-bottom: 20px;"
                :range="true"
                formatted="ll"
                color="#34495e"
                :no-label="true"
                :custom-shortcuts="customDateRangeShortcuts"
                :only-date="true"
                :auto-close="false"
              ></VueCtkDateTimePicker>
          </div>
          <div class="col-sm-12 col-md-3">
            <input type="text" placeholder="Imputation" v-model="filterForm.analyticAccount" class="form-control form-control-lg">
          </div>
          <div class="col-sm-6 col-md-2">
            <b-button v-b-tooltip.hover.bottom="'Recherche'" variant="primary mr-4" @click="getFilteredList"> <i class="fas fa-search" ></i></b-button>
            <b-button v-b-tooltip.hover.bottom="'Annuler'" variant="warning"  @click="resetFilter"> <i class="fas fa-times-circle"></i></b-button>
          </div>  
        </div>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des dotations</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                <dataset v-slot="{ ds }" :ds-data="List_Dotations" >
                  <div class="row">
                    <div class="col-md-6 mb-2 mb-md-0">
                    </div>
                    <div class="col pr-5">
                      <download-excel 
                      :fields="excelheader"
                      style="cursor:pointer" 
                      class="float-right" 
                      worksheet="Liste des dotations"
                      name="liste_des_dotations.xls"
                      :data="List_Dotations">
                        <img width="40" :src="require('@/assets/images/base/excel.png')" />
                      </download-excel>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.ref }}</td>
                                <td>{{ row.requestedBy }}</td>
                                <td>{{ row.created_at }}</td>
                                <td><span v-html="row.status"></span></td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
  </div>
</template>